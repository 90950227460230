// Loading spinner
export const SHOW_LOADING_SPINNER = "SHOW_LOADING_SPINNER";
export const HIDE_LOADING_SPINNER = "HIDE_LOADING_SPINNER";

// User data
export const SAVE_TOKEN_DATA = "SAVE_TOKEN_DATA";
export const SAVE_PGID = "SAVE_PGID";
export const GET_TOKEN_DATA = "GET_TOKEN_DATA";
export const REMOVE_TOKEN_DATA = "REMOVE_TOKEN_DATA";
export const SAVE_CURRENT_PATIENT = "SAVE_CURRENT_PATIENT";
export const REMOVE_CURRENT_PATIENT = "REMOVE_CURRENT_PATIENT";
export const SAVE_FAMILY_MEMBERS_LIST = "SAVE_FAMILY_MEMBERS_LIST";
export const SAVE_PATIENT_AND_FAMILY_MEMBERS_LIST =
  "SAVE_PATIENT_AND_FAMILY_MEMBERS_LIST";
export const REMOVE_FAMILY_MEMBERS_LIST = "REMOVE_FAMILY_MEMBERS_LIST";
export const SET_IS_FAMILY_MEMBERS_LIST_CHANGED =
  "SET_IS_FAMILY_MEMBERS_LIST_CHANGED";
export const SET_IS_ONLINE_CSR_AVAILABLE = "SET_IS_ONLINE_CSR_AVAILABLE";
export const SET_LOGGED_IN_PATIENT_PHOTO_ID = "SET_LOGGED_IN_PATIENT_PHOTO_ID";
export const SET_IS_ON_DEMAND_CAPTURE_CREDIT_OR_DEBIT_CARD_ENABLED =
  "SET_IS_ON_DEMAND_CAPTURE_CREDIT_OR_DEBIT_CARD_ENABLED";
export const SET_IS_SCHEDULED_APPOINTMENT_CAPTURE_CREDIT_OR_DEBIT_CARD_ENABLED =
  "SET_IS_SCHEDULED_APPOINTMENT_CAPTURE_CREDIT_OR_DEBIT_CARD_ENABLED";
export const SET_IS_PHARMACY_SELECTION_ENABLED =
  "SET_IS_PHARMACY_SELECTION_ENABLED";
export const SET_IS_RELOAD_PATIENT_STATUS_SUMMARY =
  "SET_IS_RELOAD_PATIENT_STATUS_SUMMARY";
export const SET_HAS_ON_DEMAND_PROMO_CODE = "SET_HAS_ON_DEMAND_PROMO_CODE";
export const SET_HAS_SCHEDULER_PROMO_CODE = "SET_HAS_SCHEDULER_PROMO_CODE";
export const SET_HAS_SECOND_OPINION_PROMO_CODE =
  "SET_HAS_SECOND_OPINION_PROMO_CODE";
export const SET_ON_DEMAND_CAMPAIGN_CHARGE = "SET_ON_DEMAND_CAMPAIGN_CHARGE";
export const SET_SCHEDULER_CAMPAIGN_CHARGE = "SET_SCHEDULER_CAMPAIGN_CHARGE";
export const SET_SECOND_OPINION_CHARGE = "SET_SECOND_OPINION_CHARGE";
export const SET_IS_SUBMITTED_FORM_REVIEW_ENABLED =
  "SET_IS_SUBMITTED_FORM_REVIEW_ENABLED";
export const SET_SMART_SCAN_CHARGE = "SET_SMART_SCAN_CHARGE";
export const SET_SELECTED_PATIENT_INSURANCE = "SET_SELECTED_PATIENT_INSURANCE";
export const SET_SELECTED_PATIENT_FOR_APPOINTMENT =
  "SET_SELECTED_PATIENT_FOR_APPOINTMENT";
// App Settings
export const SAVE_APP_SETTINGS_DATA = "SAVE_APP_SETTINGS_DATA";

// File Viewer
export const SET_FILE_URL_TO_OPEN = "SET_FILE_URL_TO_OPEN";
export const SET_FILE_NAME = "SET_FILE_NAME";

//Denticon Patient
export const SET_EXTERNAL_PATIENT_ID = "SET_EXTERNAL_PATIENT_ID";
export const SET_EXTERNAL_EMPLOYEE_ID = "SET_EXTERNAL_EMPLOYEE_ID";
export const SET_EXTERNAL_PATIENT_EMAIL = "SET_EXTERNAL_PATIENT_EMAIL";

export const SET_SHOULD_OPEN_VA_CHAT_FROM_CICI =
  "SET_SHOULD_OPEN_VA_CHAT_FROM_CICI";
