import React from "react";
import No_preview_available from "../../assets/images/No_preview_available.jpg";
import { onImageError } from "../../helpers/utils";
import { useSelector } from "react-redux";
export function DirectDentistReferral(props) {
  const { directReferral } = props;

  const shouldShowDirectDentistDetailInfo = useSelector(
    (state) =>
      state?.appSettings?.settingsData?.shouldShowDirectDentistDetailInfo
  );

  return (
    <div className="provider-card">
      {directReferral != null && (
        <>
          {shouldShowDirectDentistDetailInfo && (
            <>
              <div className="primary-border-color">
                <h2 className="text-primary w-100">About Referred Provider</h2>
              </div>
              <div className="provider-image">
                {directReferral.photoId ? (
                  <img
                    src={directReferral.photoId}
                    onError={onImageError}
                    className="mb-3 mt-3 provider-profile-img"
                  />
                ) : (
                  <img
                    src={No_preview_available}
                    className="mb-3 mt-3 provider-profile-img"
                  />
                )}
              </div>
              <h2>{directReferral?.fullName}</h2>
            </>
          )}
          <div className="provider-info">
            {shouldShowDirectDentistDetailInfo && (
              <>
                <p style={{ marginBottom: "0px", marginTop: "15px" }}>
                  <strong>Phone: </strong>
                  {directReferral?.user?.phone}
                </p>
                <p>
                  <strong>Email: </strong>
                  {directReferral?.user?.email}
                </p>
              </>
            )}
            <p>
              <strong>Practice Website: </strong>
              <a
                href={directReferral.practiceWebsiteUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                {directReferral.practiceWebsiteUrl}
              </a>
            </p>
          </div>
        </>
      )}
    </div>
  );
}
