import { TextToSpeechAvailableForLanguages } from "../../constants";

const voiceSupportedLanguages = TextToSpeechAvailableForLanguages.join();

export const initGoogleTranslateElement = () => {
  new window.google.translate.TranslateElement(
    {
      pageLanguage: "en",
      includedLanguages: voiceSupportedLanguages,
      layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE
    },
    "google_translate_element"
  );
};

export const handleTranslateClick = (event) => {
  if (
    event?.target?.id === "google_translate_element" ||
    event?.target?.closest("#google_translate_element")
  ) {
    event.preventDefault();
  }
};

export const handleGoogleTranslatorOnPageLoad = () => {
  let googleTranslateElementScript = document.createElement("script");
  googleTranslateElementScript.setAttribute(
    "src",
    "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
  );
  document.body.appendChild(googleTranslateElementScript);
  document.body.classList.add("notranslate");
  window.googleTranslateElementInit = initGoogleTranslateElement;
  handleTranslateClick();
  document.addEventListener("click", handleTranslateClick);
};

export const getTranslatedLanguageCode = () => {
  const cookieValue = `; ${document.cookie}`;
  const parts = cookieValue.split(`; googtrans=`);

  if (parts.length >= 2) {
    const googtransValue = parts[1].split(";").shift();
    const targetLang = googtransValue.split("/")[2];

    return targetLang || null;
  }

  return null;
};
