import React from "react";
import playIcon from "../../assets/images/rec-play-icon.png";
import pauseIcon from "../../assets/images/rec-pause-icon.png";
import { CICITokens } from "../../constants";
import { setShouldOpenVAChatFromCICI } from "../../redux/actions/userData";
import { useDispatch } from "react-redux";

const ReceivedMessage = ({
  message,
  userImg,
  playBtnClicked,
  pauseBtnClicked,
  isPlaying,
  id,
  shouldShowPlayBtn = true
}) => {
  const dispatch = useDispatch();
  function onMessageClicked() {
    if (message) {
      handleVAChatClick();
    }
  }

  function handleVAChatClick() {
    if (message.toLowerCase().includes(CICITokens.liveAgent)) {
      if (window.$("#va-toast0").length > 0) {
        window.$("#va-toast0").click();
      } else if (window.$("#va-chat-button").length > 0) {
        dispatch(setShouldOpenVAChatFromCICI(true));
        window.$("#va-chat-button").click();
      } else if (window.$("#va-chat-trigger-button").length > 0) {
        window.$("#va-chat-trigger-button").click();
      }
    }
  }

  return (
    <>
      <div className="chatbox">
        <img src={userImg} className="author-img rounded-circle" />
        <div
          onClick={onMessageClicked}
          className="chat-msg msg-output"
          id={id}
          style={{ display: "table" }}
          dangerouslySetInnerHTML={{
            __html: message
          }}
        ></div>
        {shouldShowPlayBtn && (
          <div className="toggle" data-play="false">
            {!isPlaying ? (
              <img
                src={playIcon}
                className="author-img recPlay"
                onClick={playBtnClicked}
              />
            ) : (
              <img
                src={pauseIcon}
                className="author-img "
                onClick={pauseBtnClicked}
              />
            )}
          </div>
        )}
        <br />
      </div>
    </>
  );
};

export default ReceivedMessage;
