import React from "react";
import AnonymousCiCiChat from "../../components/Chat/AnonymousCiCiChat";
import {
  handleApiErrors,
  queryStringToJSON,
  showAlertDialouge,
  extractCustomLinkQueryParams,
  executeDentistDirectReferralProcess
} from "../../helpers/utils";

const AnonymousChat = () => {
  const params = queryStringToJSON();
  let providerId = 0;

  if (params.providerid) {
    providerId = params.providerid;
  }

  let sessionId = providerId + "_" + Date.now();
  return (
    <>
      <AnonymousCiCiChat sessionId={sessionId} providerId={providerId} />
    </>
  );
};

export default AnonymousChat;
