import React from "react";
import iconChat from "./../../assets/images/icon-chat.svg";

const ChatTriggerButton = (props) => {
  const { unreadMessageCount, setUnreadMessageCount, scrollToBottom } = props;

  function chatTriggerBtnClicked() {
    setUnreadMessageCount(0);
    scrollToBottom();
  }

  return (
    <button
      id="va-chat-trigger-button"
      type="button"
      className="chat-button bg-white p-2 mb-4 me-4 border-0 rounded-circle position-fixed bottom-0 end-0 shadow"
      data-bs-toggle="modal"
      data-bs-target="#chatModal"
      onClick={chatTriggerBtnClicked}
    >
      <img className="w-100 h-100 object-contain" src={iconChat} alt="" />
      <span className="badge bg-primary fs-6 fw-normal rounded-pill px-1 mt-3 position-absolute top-0 start-100 translate-middle">
        {unreadMessageCount}
      </span>
    </button>
  );
};

export default ChatTriggerButton;
