import React, { useState, useEffect, useRef } from "react";
import { HubConnectionBuilder } from "@microsoft/signalr";

import ChatToaster from "../../components/ChatBox/ChatToaster";
import ChatTriggerButton from "../../components/ChatBox/ChatTriggerButton";
import ChatModal from "../../components/ChatBox/ChatModal";
import {
  getOnlineCsrs_Ajax,
  getChatRoom_Ajax,
  getChatRoomMessages_Ajax,
  getChatMessageAttachments_Ajax,
  saveMessage_Ajax,
  getChatParticipantInfo_Ajax
} from "../../helpers/requests";
import { IntervalTimes, Pagination } from "../../constants";
import { getPatientInfo, getTokenData } from "../../helpers/authManager";
import {
  getLocalTimezoneOffset,
  handleApiErrors,
  showAlertDialouge
} from "../../helpers/utils";
import { useDispatch, useSelector } from "react-redux";
import {
  hideLoadingSpinner,
  showLoadingSpinner
} from "../../redux/actions/loadingSpinner";
import moment from "moment";
import $ from "jquery";
import {
  setIsOnlineCsrAvailable,
  setShouldOpenVAChatFromCICI
} from "../../redux/actions/userData";
import { getChatHubUrl } from "../../ApplicationSettings";

const ChatBox = () => {
  const dispatch = useDispatch();

  const [connection, setConnection] = useState(null);
  const [onlineCsrs, setOnlineCsrs] = useState([]);
  const [chatRoom, setChatRoom] = useState(null);
  const [unOrderedChatMessages, setUnOrderedChatMessages] = useState([]);
  const [chatMessages, setChatMessages] = useState([]);
  const [currentUserId, setCurrentUserId] = useState(0);
  const [csrUserId, setCsrUserId] = useState(0);
  const [csrPhotoId, setCsrPhotoId] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [messageInput, setMessageInput] = useState(null);
  const [unreadMessageCount, setUnreadMessageCount] = useState(0);
  const [onlineCsrFetchApiInProgress, setOnlineCsrFetchApiInProgress] =
    useState(0);
  const [chatMessagesFetchApiInProgress, setChatMessagesFetchApiInProgress] =
    useState(false);
  const [fileUrlToOpen, setFileUrlToOpen] = useState(null);
  const [sendApiInProgress, setSendApiInProgress] = useState(false);
  const [isOnlineCsrToastListShown, setIsOnlineCsrToastListShown] =
    useState(true);

  const currentPatientInfo = useRef(null);
  const latestChat = useRef([]);
  const chatRoomRef = useRef(null);
  const chatBoxRef = useRef(null);
  const connectionRef = useRef(null);
  const currentUserIdRef = useRef(null);
  const unOrderedChatMessagesRef = useRef([]);
  const onlineCsrRef = useRef([]);
  const scrollBarPosition = useRef(0);

  function resetAllChatData() {
    connection.stop();
    connectChatHub();
    setCsrUserId(0);
    setUnOrderedChatMessages([]);
    setChatMessages([]);
    setChatRoom(null);
    latestChat.current = [];
    chatRoomRef.current = null;
    connectionRef.current = null;
    currentUserIdRef.current = null;
    unOrderedChatMessagesRef.current = [];
    getCurrentPatient();
  }

  useEffect(() => {
    connectChatHub();
    getCurrentPatient();
    getOnlineCsrs();

    const interval = setInterval(() => {
      getOnlineCsrs();
    }, IntervalTimes.Minute_MS_30);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (connection) {
      connectionRef.current = connection;
      connection
        .start()
        .then((result) => {
          connection.on("ReceiveBroadCastedMessageV2", (signalrMessageData) => {
            if (signalrMessageData) {
              var messageData = {
                chatRoomId: signalrMessageData.chatRoomId,
                senderId: signalrMessageData.senderId,
                receiverId: signalrMessageData.receiverIds,
                message: signalrMessageData.message,
                sender: signalrMessageData.sender,
                chatMessageId: signalrMessageData.messageId
              };

              var receiverIdList = signalrMessageData.receiverIds
                .split(",")
                .map(Number);
              var isCurrentUserInReceiverList =
                Array.isArray(receiverIdList) &&
                receiverIdList.indexOf(currentUserIdRef.current) > -1;

              if (isCurrentUserInReceiverList) {
                var existingMessages = [...latestChat.current];
                receiveMessage(messageData, existingMessages);
              }
            }
          });
        })
        .catch((e) => {
          handleApiErrors(e);
        });
    }
  }, [connection]);

  function getCurrentPatient() {
    var patientInfo = getPatientInfo();
    if (patientInfo.userId) {
      currentUserIdRef.current = patientInfo.userId;
      setCurrentUserId(patientInfo.userId);
      getChatParticipantInfo(patientInfo.userId);
    }
  }

  function getChatParticipantInfo(chatParticipantId) {
    if (chatParticipantId && chatParticipantId > 0) {
      dispatch(showLoadingSpinner());
      getChatParticipantInfo_Ajax(
        chatParticipantId,
        function (response) {
          dispatch(hideLoadingSpinner());
          if (
            response &&
            response.success &&
            response.data &&
            response.data.chatParticipant
          ) {
            currentPatientInfo.current = response.data.chatParticipant;
          } else if (response && !response.success && response.message) {
            showAlertDialouge("Error", response.message);
          }
        },
        function (err) {
          handleApiErrors(err);
          dispatch(hideLoadingSpinner());
        }
      );
    }
  }

  function connectChatHub() {
    var tokenData = getTokenData();

    const newConnection = new HubConnectionBuilder()
      .withUrl(getChatHubUrl(), {
        accessTokenFactory: () => tokenData.token
      })
      .withAutomaticReconnect()
      .build();

    setConnection(newConnection);
  }

  function getOnlineCsrs() {
    if (!onlineCsrFetchApiInProgress) {
      setOnlineCsrFetchApiInProgress(true);

      getOnlineCsrs_Ajax(
        function (response) {
          if (
            response &&
            response.success &&
            response.data &&
            response.data.onlineCsrs &&
            Array.isArray(response.data.onlineCsrs) &&
            response.data.onlineCsrs.length > 0
          ) {
            var existingOnlineCsrs = [...onlineCsrRef.current];
            var tempOnlineCsrs = [...response.data.onlineCsrs];

            tempOnlineCsrs.forEach((csr) => {
              if (
                existingOnlineCsrs &&
                Array.isArray(existingOnlineCsrs) &&
                existingOnlineCsrs.length > 0
              ) {
                var existingCsr = existingOnlineCsrs.find(
                  (c) => c.staffId == csr.staffId
                );
                if (existingCsr && existingCsr.isToastHidden) {
                  csr.isToastHidden = existingCsr.isToastHidden;
                }
              }
            });

            var oneOnlineCsr = tempOnlineCsrs.slice(0, 1);

            setOnlineCsrs([...oneOnlineCsr]);
            onlineCsrRef.current = [...oneOnlineCsr];
            dispatch(setIsOnlineCsrAvailable(true));
          } else if (response && !response.success) {
            setOnlineCsrs([]);
            onlineCsrRef.current = [];
            dispatch(setIsOnlineCsrAvailable(false));
            dispatch(setShouldOpenVAChatFromCICI(false));
          }

          setOnlineCsrFetchApiInProgress(false);
        },
        function (err) {
          setOnlineCsrFetchApiInProgress(false);
          setIsOnlineCsrAvailable(false);
          handleApiErrors(err);
        }
      );
    }
  }

  function increaseUnreadMessageCount() {
    var currentCount = unreadMessageCount;
    setUnreadMessageCount(currentCount + 1);
  }

  function receiveMessage(messageData, existingMessages) {
    var chatRoomDetails = chatRoomRef.current;

    if (
      messageData &&
      messageData.chatRoomId &&
      chatRoomDetails &&
      chatRoomDetails.chatRoomId &&
      messageData.chatRoomId == chatRoomDetails.chatRoomId
    ) {
      dispatch(showLoadingSpinner());
      getChatMessageAttachments_Ajax(
        messageData.chatMessageId,
        function (response) {
          dispatch(hideLoadingSpinner());
          var attachments = [];
          if (
            response &&
            response.data &&
            Array.isArray(response.data) &&
            response.data.length > 0
          ) {
            attachments = response.data;
          }

          messageData.chatMessageAttachments = attachments;

          AddMessageToChatRoom(messageData, existingMessages);
          storeUnorderedMessages(messageData);
          increaseUnreadMessageCount();
          scrollToBottom();
        },
        function (err) {
          dispatch(hideLoadingSpinner());
          handleApiErrors(err);
        }
      );
    }
  }

  function scrollToBottom(height) {
    if (!height) {
      height = 5000;
    }

    document.getElementById("dental-chat-box").scrollTo(0, height);
  }

  function AddMessageToChatRoom(messageData, existingMessages) {
    if (messageData) {
      messageData.time = moment().format("LT");
      messageData.date = moment().format("dddd MMM DD, YYYY");

      if (
        messageData.chatMessageAttachments &&
        Array.isArray(messageData.chatMessageAttachments) &&
        messageData.chatMessageAttachments.length > 0
      ) {
        messageData.chatMessageAttachments.forEach((attachment, index) => {
          if (
            attachment &&
            attachment.file &&
            attachment.file.name &&
            attachment.url
          ) {
            var newAttachmentObj = {
              photoId: attachment.url,
              fileName: attachment.file.name
            };

            messageData.chatMessageAttachments[index] = newAttachmentObj;
          }
        });
      }
    }

    if (
      !existingMessages ||
      (Array.isArray(existingMessages) && existingMessages.length <= 0)
    ) {
      existingMessages = [...chatMessages];
    }

    if (existingMessages && Array.isArray(existingMessages)) {
      var existingMessageGroup = false;

      if (existingMessages && Array.isArray(existingMessages)) {
        existingMessages.forEach((message) => {
          if (message.field == messageData.date) {
            existingMessageGroup = true;
            message.groupList.push(messageData);
          }
        });
      }

      if (!existingMessageGroup) {
        var messageArr = [];
        messageArr.push(messageData);

        var groupObj = {
          field: messageData.date,
          groupList: messageArr
        };

        existingMessages.push(groupObj);
      }

      setChatMessages(existingMessages);
      latestChat.current = existingMessages;
      scrollToBottom();
    }
  }

  function storeUnorderedMessages(messageData) {
    var tempUnOrderedChatMessages = unOrderedChatMessagesRef.current;
    var tempMessageData = {
      ...messageData,
      sender: null,
      photos: [],
      docs: []
    };

    tempUnOrderedChatMessages.push(tempMessageData);
    setUnOrderedChatMessages(tempUnOrderedChatMessages);
    unOrderedChatMessagesRef.current = tempUnOrderedChatMessages;
  }

  function handleScrollToTop(event) {
    if (event && event.target) {
      let element = event.target;

      if (scrollBarPosition.current > 0) {
        element.scrollTop = scrollBarPosition.current;
        scrollBarPosition.current = 0;
      }

      if (element.scrollTop == 0 && chatRoom && chatRoom.chatRoomId) {
        getChatRoomMessages(chatRoom.chatRoomId, 50);
      }
    }
  }

  function getChatRoomMessages(chatRoomId, scrollHeight) {
    if (chatRoomId) {
      var pageOffset = 0;
      var itemPerPage = Pagination.ItemPerPage;
      var localTimezoneOffset = getLocalTimezoneOffset();

      if (
        unOrderedChatMessages &&
        Array.isArray(unOrderedChatMessages) &&
        unOrderedChatMessages.length > 0
      ) {
        pageOffset = unOrderedChatMessages.length;
      }

      var params = {
        itemPerPage: itemPerPage,
        pageOffset: pageOffset,
        localTimezoneOffset: localTimezoneOffset
      };

      setChatMessagesFetchApiInProgress(true);
      dispatch(showLoadingSpinner());
      getChatRoomMessages_Ajax(
        chatRoomId,
        params,
        function (response) {
          dispatch(hideLoadingSpinner());
          setChatMessagesFetchApiInProgress(false);

          if (response && response.success && response.data) {
            let existingMessages = [...unOrderedChatMessages];

            var messages = response.data;
            messages.forEach((message) => {
              var sentTime = message.time;
              message.date = moment(sentTime).format("dddd MMM DD, YYYY");
              message.time = moment(sentTime).format("LT");
            });

            existingMessages.map((message) => {
              messages.push(message);
            });

            setUnOrderedChatMessages(messages);
            unOrderedChatMessagesRef.current = messages;

            if (Array.isArray(messages)) {
              var messagesGroupedByDate = messages.groupBy("date");
            }

            setChatMessages(messagesGroupedByDate);
            scrollToBottom(scrollHeight);

            latestChat.current = messagesGroupedByDate;
          }
        },
        function (err) {
          setChatMessagesFetchApiInProgress(false);
          dispatch(hideLoadingSpinner());
          handleApiErrors(err);
        }
      );
    }
  }

  function getChatRoom(csr) {
    if (csr && csr.userId) {
      resetAllChatData();
      setCsrUserId(csr.userId);
      setCsrPhotoId(csr.photoId);

      var patientInfo = getPatientInfo();
      if (patientInfo && patientInfo.userId) {
        var chatParticipants = [];
        chatParticipants.push(csr.userId);
        chatParticipants.push(patientInfo.userId);

        dispatch(showLoadingSpinner());
        getChatRoom_Ajax(
          patientInfo.userId,
          chatParticipants,
          function (response) {
            dispatch(hideLoadingSpinner());

            if (
              response &&
              response.success &&
              response.data &&
              response.data.chatRoom &&
              response.data.chatRoomId
            ) {
              chatRoomRef.current = response.data.chatRoom;
              setChatRoom(response.data.chatRoom);
              getChatRoomMessages(response.data.chatRoomId);
            }
          },
          function (err) {
            handleApiErrors(err);
            dispatch(hideLoadingSpinner());
          }
        );
      }
    }
  }

  function isAllCsrToastHidden() {
    var tempOnlineCsrs = [...onlineCsrRef.current];

    return tempOnlineCsrs.every((csr) => {
      return csr.isToastHidden;
    });
  }

  function toggleShowOnlineCSRToastList() {
    var tempOnlineCsrs = [...onlineCsrRef.current];

    if (isAllCsrToastHidden()) {
      tempOnlineCsrs.forEach((csr) => {
        if (csr.isToastHidden) {
          csr.isToastHidden = false;
        }
      });

      setOnlineCsrs(tempOnlineCsrs);
      getOnlineCsrs();
    } else {
      tempOnlineCsrs.forEach((csr) => {
        csr.isToastHidden = true;
      });

      setOnlineCsrs(tempOnlineCsrs);
    }
  }

  function sendBtnClicked() {
    if (
      !sendApiInProgress &&
      csrUserId &&
      currentUserId &&
      chatRoom &&
      (messageInput ||
        (uploadedFiles &&
          Array.isArray(uploadedFiles) &&
          uploadedFiles.length > 0)) &&
      currentPatientInfo &&
      currentPatientInfo.current
    ) {
      var shouldSendEmailNotification = false;

      var formData = new FormData();

      if (messageInput) {
        formData.append("ChatMessage", messageInput);
      }

      var attachmentArr = [];

      if (
        uploadedFiles &&
        Array.isArray(uploadedFiles) &&
        uploadedFiles.length
      ) {
        uploadedFiles.map((attachment) => {
          attachmentArr.push(attachment);
          formData.append("attachments", attachment.file);
        });
      }

      var messageSender = currentPatientInfo.current;
      var messageSenderInfo = {};

      if (
        messageSender &&
        messageSender.userId &&
        messageSender.userId == currentUserId
      ) {
        messageSenderInfo.userId = messageSender.userId;
        messageSenderInfo.photoId = messageSender.photoId
          ? messageSender.photoId
          : "";
        messageSenderInfo.name = messageSender.name ? messageSender.name : "";
        messageSenderInfo.respPartyId =
          messageSender.respPartyId > 0 ? messageSender.respPartyId : 0;
      }

      setSendApiInProgress(true);
      dispatch(showLoadingSpinner());
      saveMessage_Ajax(
        chatRoom.chatRoomId,
        currentUserId,
        shouldSendEmailNotification,
        formData,
        function (response) {
          setSendApiInProgress(false);
          dispatch(hideLoadingSpinner());
          if (
            response &&
            response.success &&
            response.data &&
            response.data.chatMessageId
          ) {
            var messageData = {
              chatRoomId: chatRoom.chatRoomId,
              senderId: currentUserId,
              receiverId: csrUserId,
              message: messageInput,
              chatMessageAttachments: attachmentArr,
              chatMessageId: response.data.chatMessageId,
              time: moment().format("LT"),
              date: moment().format("dddd MMM DD, YYYY")
            };

            setMessageInput("");
            storeUnorderedMessages(messageData);

            AddMessageToChatRoom(messageData);
            setUploadedFiles([]);
            scrollToBottom();
            BroadcastChatMessage(
              chatRoom.chatRoomId,
              currentUserId,
              csrUserId.toString(),
              messageInput,
              response.data.chatMessageId,
              messageSenderInfo
            );
          }
        },
        function (err) {
          setSendApiInProgress(false);
          dispatch(hideLoadingSpinner());
          handleApiErrors(err);
        }
      );
    }
  }

  function BroadcastChatMessage(
    chatRoomId,
    senderId,
    receiverUserIds,
    message,
    messageId,
    messageSenderInfo
  ) {
    const signalrMessageData = {
      chatRoomId: chatRoomId,
      messageId: messageId,
      senderId: senderId,
      receiverIds: receiverUserIds,
      message: message,
      sender: messageSenderInfo
    };
    connection
      .invoke("BroadCastMessageV2", signalrMessageData, receiverUserIds)
      .catch(function (err) {
        return false;
      });

    return true;
  }

  return (
    <div id="chat-module">
      {csrUserId ? (
        <ChatTriggerButton
          unreadMessageCount={unreadMessageCount}
          setUnreadMessageCount={setUnreadMessageCount}
          scrollToBottom={scrollToBottom}
        />
      ) : (
        <ChatToaster
          onlineCsrs={onlineCsrs}
          setOnlineCsrs={setOnlineCsrs}
          getChatRoom={getChatRoom}
          toggleShowOnlineCSRToastList={toggleShowOnlineCSRToastList}
          onlineCsrRef={onlineCsrRef}
        />
      )}
      <ChatModal
        chatRoom={chatRoom}
        chatMessages={chatMessages}
        currentUserId={currentUserId}
        uploadedFiles={uploadedFiles}
        setUploadedFiles={setUploadedFiles}
        sendBtnClicked={sendBtnClicked}
        messageInput={messageInput}
        setMessageInput={setMessageInput}
        chatBoxRef={chatBoxRef}
        chatMessagesFetchApiInProgress={chatMessagesFetchApiInProgress}
        fileUrlToOpen={fileUrlToOpen}
        setFileUrlToOpen={setFileUrlToOpen}
        handleScrollToTop={handleScrollToTop}
        scrollToBottom={scrollToBottom}
        csrUserId={csrUserId}
        csrPhotoId={csrPhotoId}
        resetAllChatData={resetAllChatData}
        setCsrUserId={setCsrUserId}
        scrollBarPosition={scrollBarPosition}
      />
    </div>
  );
};

export default ChatBox;
